import Axios from 'axios';
import { debounce } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FullContent } from '../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType, useCancelToken } from '../shared';
import { HiddenGeoDataModule, HiddenSalesDataModule } from './HiddenPerformanceModule';
import { PerformanceSummaryRow } from './PerformanceSummaryRow';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import {
  CategoryPerformance,
  ChannelPerformance,
  PerformanceStats,
  RetailerPerformance,
  VendorPerformance,
} from './submodules';
import { GeolocationNoMapPerformance } from './submodules/GeolocationNoMapPerformance';
import { apiToPerformanceStats, DefaultPerformanceStats } from './submodules/shared';
import TopSellingDesignRow from './TopSellingDesignRow';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

export const CategoriesPerformancePage = () => {
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);
  const [totals, setTotals] = React.useState({
    sales: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    royalties: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    units: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    avgUnitSales: { current: 0, previous: 0 },
    avgUnitRoyalties: { current: 0, previous: 0 },
  });
  const location = useLocation();
  const routeParams = useParams();
  const query = queryString.parse(location.search);
  const performanceQP = usePerformanceQueryParams();
  const isFiltered = query['category'] ? true : false;
  const cancelToken = useCancelToken();
  const singleCategoryId = query.category ? parseInt(query.category as string, 10) : null;
  const showTopSellingDesignRow = query.vendor || query.retailer || query.category || query.channel;

  React.useEffect(() => {
    getData();
  },              [location.search]);

  const getData = async () => {
    if (cancelToken) {
      cancelToken.cancelToken();
    }

    const newToken = Axios.CancelToken.source();
    cancelToken.setToken(newToken);
    setLoading(true);

    const queryParams = performanceQP.toApi('category', [
      { label: 'year', value: null },
    ]);
    try {
      const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`, { cancelToken: newToken.token });
      if (analytics) {
        const data = analytics.data.data;
        const s = apiToPerformanceStats(data);
        setTotals({
          sales: {
            previous: { total: Number(data.totals.yoy_total_sales), filtered: Number(data.totals.yoy_total_sales) },
            current: { total: Number(data.totals.total_sales), filtered: Number(data.totals.total_sales) },
          },
          royalties: {
            previous: { total: Number(data.totals.yoy_total_royalties), filtered: Number(data.totals.yoy_total_royalties) },
            current: { total: Number(data.totals.total_royalties), filtered: Number(data.totals.total_royalties) },
          },
          units: {
            previous: { total: Number(data.totals.yoy_total_units), filtered: Number(data.totals.yoy_total_units) },
            current: { total: Number(data.totals.total_units), filtered: Number(data.totals.total_units) },
          },
          avgUnitSales: {
            previous: Number(data.totals.yoy_avg_unit_sales),
            current: Number(data.totals.avg_unit_sales),
          },
          avgUnitRoyalties: {
            previous: Number(data.totals.yoy_avg_unit_royalties),
            current: Number(data.totals.avg_unit_royalties),
          },
        });
        setStats(s);
        setLoading(false);
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching data:', error);
      }
    }
  };

  const getAccountID = () => {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  };

  const calculatePercentage = (current: number, previous: number) => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const salesPercentage = calculatePercentage(totals.sales.current.filtered, totals.sales.previous.filtered);
  const royaltiesPercentage = calculatePercentage(totals.royalties.current.filtered, totals.royalties.previous.filtered);
  const unitsPercentage = calculatePercentage(totals.units.current.filtered, totals.units.previous.filtered);
  const avgUnitSalesPercentage = calculatePercentage(totals.avgUnitSales.current, totals.avgUnitSales.previous);
  const avgUnitRoyaltiesPercentage = calculatePercentage(totals.avgUnitRoyalties.current, totals.avgUnitRoyalties.previous);

  let additionalGraphs;
  if (isFiltered) {
    additionalGraphs = (
      <div>
        {stats.salesDataDisplayable ? (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <VendorPerformance
                      vendors={stats.vendors}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <ChannelPerformance
                      channels={stats.channels}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <RetailerPerformance
                      retailers={stats.retailers}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <GeolocationNoMapPerformance/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="panel panel-portal">
            <div className="panel-body">
              <HiddenSalesDataModule />
            </div>
          </div>
        )}
        {stats.salesDataDisplayable && performanceQP.isVendorFiltered() ? (
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body" style={{ textAlign: 'center' }}>
                  <h4>Royalty Report Data Export</h4>
                  <p className="text-muted">Export royalty report data for this vendor.</p>
                  <Link target="_blank" to={`/api/royalty-analytics/vendors/${performanceQP.params.vendor}/downloads?${performanceQP.toApi('download')}`}>
                    <button className="btn btn-default">
                      <i className="fa fa-download" /> Download Export
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <FullContent>
      <PerformanceTitleFilters
        title="Categories"
        subTitle="Sales by product/service category."
        hideQuarter={true}
        hideYear={true}
        hideStartEndDate={false}
        isAggregate={stats.isAggregate}
        isCurrent={stats.isCurrent}
        loading={loading}
      />
      <PerformanceSummaryRow
        loading={loading}
        totals={totals}
        salesPercentage={salesPercentage}
        royaltiesPercentage={royaltiesPercentage}
        unitsPercentage={unitsPercentage}
        avgUnitSalesPercentage={avgUnitSalesPercentage}
        avgUnitRoyaltiesPercentage={avgUnitRoyaltiesPercentage}
      />
      {showTopSellingDesignRow && (
        <div className="row">
          <TopSellingDesignRow
            accountId={getAccountID()}
            hideIfNoResults={true}
          />
        </div>
      )}
      {(stats.salesDataDisplayable || isFiltered) &&
      !(stats.categories.length === 1 && stats.categories[0].category.id === singleCategoryId) || loading ? (
        <div className="panel panel-portal">
          <div className="panel-body">
            <CategoryPerformance
              categories={stats.categories}
              loading={loading}
              large={true}
              isAggregate={stats.isAggregate}
            />
          </div>
        </div>
      ) : null}
      {additionalGraphs}
    </FullContent>
  );
};
