import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface PerformanceSummaryProps {
  title: string;
  amount: number | string;
  percentage: number;
  isPositive: boolean;
  totalPercentage: number;
  hidePanel?: boolean;
}

export const PerformanceSummaryGridItem: React.FC<PerformanceSummaryProps> = ({ title, amount, percentage, isPositive, totalPercentage, hidePanel }) => {
  const description = `${totalPercentage.toFixed(1)}% of total ${title.toLowerCase()}`;
  const content = (
    <>
      <div className="text-primary">{title}</div>
      <div style={{ fontSize: '24px', fontWeight: 500, lineBreak: 'anywhere' }}>
        {typeof amount === 'number' ? amount.toLocaleString() : amount}
      </div>
      {percentage !== 0 && (
        <div className={`text-${isPositive ? 'success' : 'danger'}`}>
          <FontAwesomeIcon icon={isPositive ? faCaretUp : faCaretDown} />{' '}
          {isNaN(percentage) ? 'N/A' : percentage.toFixed(1)}%
        </div>
      )}
      {totalPercentage < 100 && <div className="text-muted">{description}</div>}
    </>
  );

  if (hidePanel) {
    return <div>{content}</div>;
  }

  return (
    <div className="panel panel-portal">
      <div className="panel-body">{content}</div>
    </div>
  );
};
