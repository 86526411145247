import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { formattedFiscalYear } from '../../utils';

interface PerformanceQueryParams {
  licensorId: number | null;
  page: number;
  category: string | number | null;
  channel: number | null;
  year: number | null;
  quarter: string | null;
  state: string | null;
  vendor: string | number | null;
  retailer: string | number | null;
  vendorIds: string | null;
  retailerIds: string | null;
  categoryIds: string | null;
  startDate: string | null;
  endDate: string | null;
  startMonth: number | null;
  startYear: number | null;
  endMonth: number | null;
  endYear: number | null;
}

export interface AdditionalQP {
  value: any;
  label: 'category' | 'categoryIds' | 'channel' | 'year' | 'quarter' | 'state' | 'vendor' | 'retailer' | 'page' | 'retailerIds' | 'vendorIds' | 'startDate' | 'endDate' | 'startMonth' | 'startYear' | 'endMonth' | 'endYear' | 'limit' | 'sort';
}

export const usePerformanceQueryParams = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const date = new Date();
  let year: number;
  if (date.getMonth() >= 9) {
    year = date.getFullYear();
  } else {
    year = date.getFullYear() - 1;
  } // this calculates first fiscal year
  const [params, setQueryParams] = React.useState<PerformanceQueryParams>({
    year,
    licensorId: (match && match.params['licensorId']) || null,
    page: 1,
    category: null,
    channel: null,
    quarter: null,
    state: null,
    vendor: null,
    retailer: null,
    vendorIds: null,
    retailerIds: null,
    categoryIds: null,
    startDate: null,
    endDate: null,
    startMonth: null,
    startYear: null,
    endMonth: null,
    endYear: null,
  });

  const getYear = (fiscalYear: string) => {
    return fiscalYear.split('-')[0];
  };

  React.useEffect(() => {
    const p = queryString.parse(location.search);
    const currentParams = params;

    currentParams.page = p.page ? Number(p.page) : 1;

    currentParams.category = p.category && p.category.includes(',')
      ? p.category as string
      : (p.category ? Number(p.category) : null);

    currentParams.vendor = p.vendor && p.vendor.includes(',')
      ? p.vendor as string
      : (p.vendor ? Number(p.vendor) : null);

    currentParams.retailer = p.retailer && p.retailer.includes(',')
      ? p.retailer as string
      : (p.retailer ? Number(p.retailer) : null);

    currentParams.channel = p.channel ? Number(p.channel) : null;
    currentParams.year = p.year && p.year.includes('-') ? Number(getYear(p.year)) : (p.year ? Number(p.year) : year);
    currentParams.quarter = p.quarter ? p.quarter : null;
    currentParams.state = p.state ? p.state : null;
    currentParams.retailerIds = p.retailerIds ? p.retailerIds as string : null;
    currentParams.vendorIds = p.vendorIds ? p.vendorIds as string : null;
    currentParams.categoryIds = p.categoryIds ? p.categoryIds as string : null;
    currentParams.startDate = p.startDate ? p.startDate as string : null;
    currentParams.endDate = p.endDate ? p.endDate as string : null;
    currentParams.startMonth = p.startMonth ? Number(p.startMonth) : null;
    currentParams.startYear = p.startYear ? Number(p.startYear) : null;
    currentParams.endMonth = p.endMonth ? Number(p.endMonth) : null;
    currentParams.endYear = p.endYear ? Number(p.endYear) : null;

    setQueryParams(currentParams);
  },              [location.search]);

  const toApi = (dimension: string, additional?: AdditionalQP[]) => {
    const filters = {
      dimension,
      account_id: params.licensorId,
      year: params.year,
      channel_id: params.channel,
      category_id: params.category,
      page: params.page,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailer_id: params.retailer,
      vendor_id: params.vendor,
      startDate: params.startDate,
      endDate: params.endDate,
      startMonth: params.startMonth,
      startYear: params.startYear,
      endMonth: params.endMonth,
      endYear: params.endYear,
    };
    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
      });
    }
    if (dimension === 'download') {
      filters['client_id'] = params.licensorId;
    }
    if (dimension === 'topselling') {
      filters['vendor_ids'] = params.vendorIds;
      filters['retailer_ids'] = params.retailerIds;
      filters['category_ids'] = params.categoryIds;
    }
    if (dimension !== 'vendor-payment') {
      filters['startDate'] = params.startDate;
      filters['endDate'] = params.endDate;
    }
    const cleaned = omitBy(filters, isNil);
    return queryString.stringify(cleaned);
  };

  const toTopSellingDesignsApi = (additional?: AdditionalQP[]) => {
    const filters = {
      account_id: params.licensorId,
      channels: params.channel,
      categories: params.category,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailers: params.retailer,
      vendors: params.vendor,
      startMonth: params.startMonth,
      startYear: params.startYear,
      endMonth: params.endMonth,
      endYear: params.endYear,
    };
    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
      });
    }
    const cleaned = omitBy(filters, isNil);
    return queryString.stringify(cleaned);
  };

  const toMapApi = (dimension: string, additional?: AdditionalQP[]) => {
    const filters = {
      dimension,
      account_id: params.licensorId,
      // year: params.year,
      channel_id: params.channel,
      category_id: params.category,
      page: params.page,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailer_id: params.retailer,
      vendor_id: params.vendor,
      startDate: params.startDate,
      endDate: params.endDate,
      startMonth: params.startMonth,
      startYear: params.startYear,
      endMonth: params.endMonth,
      endYear: params.endYear,
    };
    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
      });
    }
    if (dimension === 'download') {
      filters['client_id'] = params.licensorId;
    }
    const cleaned = omitBy(filters, isNil);
    return queryString.stringify(cleaned);
  };

  const toFullMap = () => {
    const filters = {
      withSales: true,
      withAudience: false,
      accountId: params.licensorId,
      year: params.year,
      channelIds: params.channel,
      categoryIds: params.category,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailerIds: params.retailer,
      vendorId: params.vendor,
      startDate: params.startDate,
      endDate: params.endDate,
      startMonth: params.startMonth,
      startYear: params.startYear,
      endMonth: params.endMonth,
      endYear: params.endYear,
    };
    return omitBy(filters, isNil);
  };

  const toFullRetailerMap = () => {
    const filters = {
      withSales: false,
      withAudience: false,
      withRetailers: true,
      accountId: params.licensorId,
      year: params.year,
      channelIds: params.channel,
      categoryIds: params.category,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailerIds: params.retailer,
      vendorId: params.vendor,
      startDate: params.startDate,
      endDate: params.endDate,
      startMonth: params.startMonth,
      startYear: params.startYear,
      endMonth: params.endMonth,
      endYear: params.endYear,
    };
    return omitBy(filters, isNil);
  };

  const toQueryString = (additional?: AdditionalQP[]) => {
    const filters = {
      page: params.page > 1 ? params.page : null,
      category: params.category,
      channel: params.channel,
      quarter: params.quarter,
      state: params.state,
      vendor: params.vendor,
      retailer: params.retailer,
      year: params.year && params.year !== year ? formattedFiscalYear(params.year) : null,
      startDate: params.startDate,
      endDate: params.endDate,
      startMonth: params.startMonth,
      startYear: params.startYear,
      endMonth: params.endMonth,
      endYear: params.endYear,
    };

    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
        if (a.label === 'year' && Number(getYear(a.value)) === year) {
          filters['year'] = null;
        }
      });
    }

    const cleaned = omitBy(filters, isNil);
    return queryString.stringify(cleaned);
  };

  const createLink = (componentPath: string) => {
    const licensorRoute = params.licensorId ? `/clients/${params.licensorId}` : '';
    return `${licensorRoute}/performance/${componentPath}?${toQueryString()}`;
  };

  const isFiltered = () => {
    return toQueryString().length ? true : false;
  };

  const isVendorFiltered = () => {
    return params.vendor ? true : false;
  };

  const scrollToTop = () => {
    document.getElementsByClassName('secondary-gateway-navigation-content')[0].scrollTo(0, 0);
  };

  return { params, toApi, toMapApi, toTopSellingDesignsApi, toFullMap, toFullRetailerMap, toQueryString, createLink, isFiltered, isVendorFiltered, scrollToTop };
};
