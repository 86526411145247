import * as React from 'react';
import { GatewayModal, LoadingSpinner, ModalType } from '../shared';
import { PerformanceSummaryGridItem } from './PerformanceSummaryGridItem';

interface PerformanceSummaryRowProps {
  loading: boolean;
  totals: {
    royalties: { current: { total: number; filtered: number }; previous: { total: number; filtered: number } };
    avgUnitSales: { current: number; previous: number };
    avgUnitRoyalties: { current: number; previous: number };
    units: { current: { total: number; filtered: number }; previous: { total: number; filtered: number } };
    sales: { current: { total: number; filtered: number }; previous: { total: number; filtered: number } }
  };
  salesPercentage: number;
  royaltiesPercentage: number;
  unitsPercentage: number;
  avgUnitSalesPercentage: number;
  avgUnitRoyaltiesPercentage: number;
}

export const PerformanceSummaryRow: React.FC<PerformanceSummaryRowProps> = (props: PerformanceSummaryRowProps) => {
  return (
    <div className="row">
      <div className="col-md-6">
        {props.loading ? (
          <LoadingSpinner />
        ) : (
          <div className="row">
            <div className="col-md-6">
              <PerformanceSummaryGridItem
                title="Sales"
                amount={
                  props.totals.sales.current.filtered
                    ? props.totals.sales.current.filtered.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    : 'N/A'
                }
                percentage={props.salesPercentage}
                isPositive={props.salesPercentage >= 0}
                totalPercentage={(props.totals.sales.current.filtered / props.totals.sales.current.total) * 100}
              />
            </div>
            <div className="col-md-6">
              <PerformanceSummaryGridItem
                title="Royalties"
                amount={
                  props.totals.royalties.current.filtered
                    ? props.totals.royalties.current.filtered.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    : 'N/A'
                }
                percentage={props.royaltiesPercentage}
                isPositive={props.royaltiesPercentage >= 0}
                totalPercentage={(props.totals.royalties.current.filtered / props.totals.royalties.current.total) * 100}
              />
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6">
        {props.loading ? (
          <LoadingSpinner />
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body" style={{ padding: '0px 15px' }}>
                  <div className="row">
                    <div className="col-xs-12 col-md-4">
                      <div className="row">
                        <div className="col-xs-12" style={{ padding: '15px 5px 15px 15px' }}>
                          <PerformanceSummaryGridItem
                            title="Units"
                            amount={
                              props.totals.units.current.filtered !== undefined
                                ? props.totals.units.current.filtered.toLocaleString()
                                : 'N/A'
                            }
                            percentage={props.unitsPercentage}
                            isPositive={props.unitsPercentage >= 0}
                            totalPercentage={(props.totals.units.current.filtered / props.totals.units.current.total) * 100}
                            hidePanel={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-8">
                      <div className="row">
                        <div className="col-xs-6" style={{ padding: '15px 5px 15px 15px' }}>
                          <PerformanceSummaryGridItem
                            title="Avg Unit Sales"
                            amount={
                              props.totals.avgUnitSales.current !== undefined
                                ? props.totals.avgUnitSales.current.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })
                                : 'N/A'
                            }
                            percentage={props.avgUnitSalesPercentage}
                            isPositive={props.avgUnitSalesPercentage >= 0}
                            totalPercentage={100}
                            hidePanel={true}
                          />
                        </div>
                        <div className="col-xs-6" style={{ padding: '15px 5px 15px 15px' }}>
                          <PerformanceSummaryGridItem
                            title="Avg Unit Royalties"
                            amount={
                              props.totals.avgUnitRoyalties.current !== undefined
                                ? props.totals.avgUnitRoyalties.current.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })
                                : 'N/A'
                            }
                            percentage={props.avgUnitRoyaltiesPercentage}
                            isPositive={props.avgUnitRoyaltiesPercentage >= 0}
                            totalPercentage={100}
                            hidePanel={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
